<template>
    <DxPopup
        :visible="dialog"
        :close-on-outside-click="closeOnOutsideClick"
        :show-close-button="false"
        :show-title="false"
        :width="width"
        :height="height"
        :shading="shading"
        :onHidden="onHiddenPopup"
        :copyRootClassesToWrapper="true"
        container=".dx-viewport"
        :position="position"
        style="position: relative"
        :animation="animation"
        :class="`${classPopup} popup-style`"
    >
        <template #content>
            <div class="full-height" style="position: relative">
                <div v-if="showCloseButton">
                    <slot name="close">
                        <DxButton
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-close"
                            style="
                                position: absolute;
                                top: -17px;
                                right: -14px;
                                z-index: 1;
                            "
                            @click="$emit('close')"
                        />
                    </slot>
                </div>
                <div class="column full-height justify-space-between">
                    <div :class="useButton ? 'xs10' : 'xs12'">
                        <slot name="title">
                            <div v-if="title" class="title">{{ title }}</div>
                        </slot>
                        <slot name="header"></slot>
                        <DxScrollView
                            id="scrollview"
                            ref="scrollViewWidget"
                            :height="heightScrollView"
                            :scroll-by-content="true"
                        >
                            <slot name="content"></slot>
                        </DxScrollView>
                    </div>
                    <slot name="footer">
                        <div
                            style="flex-basis: 50px"
                            class="pt-2"
                            v-if="
                                useButton && (useButtonLeft || useButtonRight)
                            "
                        >
                            <div
                                class="
                                    row
                                    align-end
                                    full-height
                                    justify-space-between
                                "
                                :style="
                                    directionButton == 'row'
                                        ? 'height: 50px'
                                        : 'height: 90px'
                                "
                                style="border-top: 1px solid #dadce0"
                            >
                                <div class="full-width">
                                    <div
                                        :class="`${directionButton} justify-space-between`"
                                    >
                                        <!-- :class="`${directionButton} ${useButtonLeft? 'justify-start' : useButtonRight ? 'justify-end' : 'justify-space-between'}`" -->
                                        <slot name="buttonLeft">
                                            <DxButton
                                                v-if="useButtonLeft"
                                                :text="buttonTextLeft"
                                                :type="typeButtonLeft"
                                                styling-mode="text"
                                                :class="`rounder ${
                                                    directionButton == 'row' &&
                                                    !fullWidth
                                                        ? 'mr-2'
                                                        : ''
                                                } ${
                                                    fullWidth
                                                        ? 'full-width'
                                                        : ''
                                                }`"
                                                @click="$emit('close', false)"
                                            />
                                        </slot>
                                        <DxButton
                                            v-if="useButtonRight"
                                            :text="buttonTextRight"
                                            type="default"
                                            styling-mode="contained"
                                            :class="`rounder ${
                                                directionButton == 'row' &&
                                                !fullWidth
                                                    ? 'ml-2'
                                                    : 'mb-2'
                                            } ${fullWidth ? 'full-width' : ''}`"
                                            @click="$emit('close', true)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </template>
    </DxPopup>
</template>
<script>
import { DxScrollView, DxPopup, DxButton } from "devextreme-vue";
import { mapMutations } from "vuex";
export default {
    props: {
        width: { type: String, default: "100%" },
        height: { type: String, default: "60%" },
        heightScrollView: { type: String, default: "" },
        shading: { type: Boolean, default: true },
        useButtonRight: { type: Boolean, default: true },
        useButtonLeft: { type: Boolean, default: true },
        useButton: { type: Boolean, default: true },
        showCloseButton: { type: Boolean, default: false },
        dialog: { type: Boolean, default: false },
        buttonTextLeft: { type: String, default: "Trở về" },
        buttonTextRight: { type: String, default: "Đồng ý" },
        title: { type: String, default: "Tiêu đề" },
        directionButton: { type: String, default: "row" },
        typeButtonLeft: { type: String, default: "default" },
        classPopup: { type: String, default: "popup-style" },
        closeOnOutsideClick: { type: Boolean, default: true },
        fullWidth: { type: Boolean, default: false },
        position: { type: String, default: "bottom" },
        animation: {
            type: Object,
            default: () => ({
                show: {
                    type: "slide",
                    duration: 400,
                    from: {
                        position: {
                            my: "top",
                            at: "bottom",
                            of: "window",
                        },
                    },
                },
                hide: {
                    type: "slide",
                    duration: 400,
                    to: {
                        position: {
                            my: "top",
                            at: "bottom",
                            of: "window",
                        },
                    },
                },
            }),
        },
    },
    components: {
        DxPopup,
        DxButton,
        DxScrollView,
    },
    data() {
        return {};
    },
    methods: {
        onHiddenPopup(e) {
            this.$emit("update:dialog", false);
            // this.resetSoDoCho();
            // this.setChinhSuaSoatVe(false);
            // this.setDuocQuetMa(true);
        },
        ...mapMutations("banDo", [
            // "resetSoDoCho",
            // "setChinhSuaSoatVe",
            // "setDuocQuetMa",
        ]),
    },
    watch: {
        dialog() {
            if (!this.dialog) {
                this.$emit("close", false);
            }
        },
    },
};
</script>